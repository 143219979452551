import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { ChrComponentsModule } from '@ciphr-design-system/angular';

import { appConfigInitializerFactory } from '@ciphr/core/app-config';
import { AuthModule } from '@ciphr/core/auth';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NavigationComponent } from './navigation/navigation.component';
import { uiReducer } from './core/state/ui/ui.reducer';

@NgModule({
  declarations: [AppComponent, NavigationComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ChrComponentsModule.forRoot({
      components: true,
      defaultTheme: 'ciphr-light',
      iconsUrl: 'assets/icons',
      polyfills: true,
    }),
    AuthModule.withConfig('recruitment', {
      issuer: isDevMode() ? 'https://dev.ciphr.com' : window.location.origin,
      redirectUri: window.location.origin + '/recruitment/app',
      responseType: 'code',
      scope: 'openid profile email offline_access https://api.recruitment.ciphr.com/.default',
    }),
    AppRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    provideEffects(),
    provideStore({ ui: uiReducer }),
    provideStoreDevtools({ logOnly: !isDevMode() }),
    { provide: APP_INITIALIZER, useFactory: appConfigInitializerFactory, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
