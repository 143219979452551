import { createReducer, on } from '@ngrx/store';
import { uiActions } from './ui.actions';

export interface UiState {
  isOpen: boolean;
}

export interface ApplicationState {
  ui: UiState;
}

export const initialState: UiState = {
  isOpen: false,
};

export const uiReducer = createReducer(
  initialState,
  on(uiActions.toggleNavigation, (state) => ({
    ...state,
    isOpen: !state.isOpen,
  })),
);
