<chr-navigation (chr-switched)="handleToggle()" [ngClass]="(isOpen$ | async) ? 'chr-navigation opened' : 'chr-navigation closed'">
  <div slot="logo">
    <a routerLink="/"> <img alt="Recruitment application logo" src="assets/images/logo.svg" /> </a>
  </div>
  <chr-nav-item [selected]="true" label="Recruitment" size="m" icon="user-magnifier">
    <chr-icon name="chevron-down" size="m" slot="after"></chr-icon>
  </chr-nav-item>
  <chr-nav-item label="Dashboard" />
  <chr-nav-item label="Pipeline" />
  <chr-nav-item
    #vacancyRouterLink="routerLinkActive"
    [selected]="vacancyRouterLink.isActive"
    label="Vacancies"
    routerLink="/vacancies"
    routerLinkActive="active"
    data-test-id="navigateToVacancies"
  />
  <chr-nav-item label="Candidates" />
  <chr-nav-item label="Assessors" />
  <chr-nav-item label="Calendar" />
  <chr-nav-item label="Tasks">
    <chr-badge label="104" palette="warning" slot="after" variant="secondary" />
  </chr-nav-item>
  <chr-nav-item label="Messages" />
  <chr-nav-item
    #formsRouter="routerLinkActive"
    [selected]="formsRouter.isActive"
    label="Forms"
    routerLink="/forms"
    routerLinkActive="active"
  />
  <chr-nav-item label="Workflows" />
  <chr-nav-item label="Background screening" />
  <chr-nav-item label="Tests" />
  <chr-nav-item label="Reporting" />
  <chr-nav-item label="Settings" />
  <div class="nav-footer" slot="footer">
    <div class="nav-footer-core">
      <chr-avatar value="Jenny Willson" size="m" />
      <!-- START - waiting for Design System component  -->
      <div *ngIf="isOpen$ | async" class="userLabel">
        <span class="description">Recruiter</span>
        <span class="name">Jenny Willson</span>
      </div>
      <!-- END -->
    </div>
    <chr-button-icon *ngIf="isOpen$ | async" palette="neutral" size="s" variant="invisible" icon="three-dots"> </chr-button-icon>
  </div>
</chr-navigation>
