import { inject, Injectable } from '@angular/core';

import { filter, ReplaySubject } from 'rxjs';

import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private oauthService = inject(OAuthService);

  private signedInSource = new ReplaySubject<boolean>(1);
  signedIn$ = this.signedInSource.asObservable();

  constructor() {
    this.oauthService.events.pipe(filter((e) => e.type === 'session_changed')).subscribe(() => this.oauthService.initLoginFlow());
    this.oauthService.setupAutomaticSilentRefresh();
  }

  get userName(): string {
    const claims = this.oauthService.getIdentityClaims();

    return claims ? claims['name'] : '';
  }

  get firstNameWithLastName(): string {
    const claims = this.oauthService.getIdentityClaims();

    return claims ? claims['preferred_username'] : '';
  }

  configure(config: AuthConfig): void {
    this.oauthService.configure(config);
  }

  initLogin(): void {
    this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then(() => this.signedInSource.next(this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()));
  }

  logout(): void {
    this.oauthService.logOut();
  }
}
